var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer" }, [
    _c("div", { staticClass: "cont-item" }, [
      _c("h4", [_vm._v("模板基础信息")]),
      _c(
        "div",
        { staticClass: "option", staticStyle: { "padding-left": "0" } },
        [
          _c("i", { staticClass: "must-icon" }, [_vm._v("*")]),
          _c("span", [_vm._v("模板名称：")]),
          _c("el-input", {
            staticClass: "w240",
            attrs: {
              placeholder: "请输入名称",
              size: "mini",
              disabled: !_vm.isEdit,
            },
            model: {
              value: _vm.moduleName,
              callback: function ($$v) {
                _vm.moduleName = $$v
              },
              expression: "moduleName",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "cont-item" }, [
      _c("h4", [_vm._v("发货区域设置")]),
      _c(
        "div",
        { staticClass: "option" },
        [
          _c("i", { staticClass: "must-icon" }, [_vm._v("*")]),
          _c("span", [_vm._v("包邮区域：")]),
          _c("el-cascader", {
            attrs: {
              options: _vm.areaOptions,
              props: _vm.areaProps,
              clearable: "",
              disabled: !_vm.isEdit,
            },
            model: {
              value: _vm.freeValue,
              callback: function ($$v) {
                _vm.freeValue = $$v
              },
              expression: "freeValue",
            },
          }),
        ],
        1
      ),
      _vm.isEdit
        ? _c("p", { staticClass: "remark" }, [
            _vm._v(" 请点击输入框内容区域可编辑地址 "),
          ])
        : _vm._e(),
    ]),
    _vm.isEdit
      ? _c(
          "div",
          { staticClass: "submit-area" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary" },
                on: { click: _vm.submit },
              },
              [_vm._v(" 提交 ")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary", plain: "" },
                on: { click: _vm.goPer },
              },
              [_vm._v(" 取消 ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }