<template>
  <div class="outer">
    <div class="cont-item">
      <h4>模板基础信息</h4>
      <div
        class="option"
        style="padding-left: 0;"
      >
        <i class="must-icon">*</i>
        <span>模板名称：</span>
        <el-input
          v-model="moduleName"
          placeholder="请输入名称"
          class="w240"
          size="mini"
          :disabled="!isEdit"
        ></el-input>
      </div>
    </div>
    <div class="cont-item">
      <h4>发货区域设置</h4>
      <div class="option">
        <i class="must-icon">*</i>
        <span>包邮区域：</span>
        <el-cascader
          v-model="freeValue"
          :options="areaOptions"
          :props="areaProps"
          clearable
          :disabled="!isEdit"
        ></el-cascader>
      </div>
      <p
        v-if="isEdit"
        class="remark"
      >
        请点击输入框内容区域可编辑地址
      </p>
    </div>
    <div
      v-if="isEdit"
      class="submit-area"
    >
      <el-button
        size="mini"
        type="primary"
        @click="submit"
      >
        提交
      </el-button>
      <el-button
        size="mini"
        type="primary"
        plain
        @click="goPer"
      >
        取消
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FreightDetail',
  data() {
    return {
      moduleName: '',
      templateId: '',
      templateType: '',
      areaOptions: [], // 包邮地址数据列
      freeValue: [], // 包邮地区值
      areaProps: { // 地址得prop属性
        multiple: true,
        value: 'id',
        label: 'name',
        children: 'rows'
      },
      areaNumObj: {}, // 地址省市数量集合
      tableData: [],
      isEdit: true
    }
  },
  mounted() {
    this.isEdit = !this.$route.query.read
    this.templateId = this.$route.query.id // 获取模板id
    this.templateType = this.$route.query.type // 获取模板类型
    this.getAreaList()
  },
  methods: {
    // 返回上一页
    goPer() {
      this.$router.push({
        path: '/freight/list'
      })
    },
    // 提交
    submit() {
      const _this = this
      const {
        templateId,
        templateType,
        moduleName,
        freeValue,
        tableData,
        areaNumObj
      } = _this
      if (moduleName === '') {
        _this.$message.error('缺少必填项')
        return false
      } else if (!(freeValue && freeValue.length > 0)) {
        _this.$message.error('缺少必填项')
        return false
      }
      let provinceObj = {}
      _this.freeValue.forEach(p => {
        const pState = Object.keys(provinceObj).includes(String(p[0]))
        if (pState) {
          let cityObj = provinceObj[p[0]]
          const cityState = Object.keys(cityObj).includes(String(p[1]))
          if (cityState) {
            if (!cityObj[p[1]].includes(p[2])) {
              cityObj[p[1]].push(p[2])
            }
          } else {
            cityObj[p[1]] = [p[2]]
          }
          provinceObj[p[0]] = cityObj
        } else {
          provinceObj[p[0]] = {
            [p[1]]: [p[2]]
          }
        }
      })
      Object.keys(provinceObj).forEach(item => {
        const cityArr = []
        Object.keys(provinceObj[item]).forEach(item2 => {
          if (provinceObj[item][item2].length === Number(areaNumObj[item2])) {
            provinceObj[item][item2] = []
            cityArr.push(provinceObj[item][item2])
          }
        })
        if (cityArr.length === Number(areaNumObj[item])) {
          provinceObj[item] = []
        }
      })

      const ruleList = [{
        id: templateId ? tableData[0].id : 0,
        fee_type: 0,
        first_num: 0,
        first_fee: 0,
        each_add: 0,
        each_add_fee: 0,
        free_type: 0,
        free_value: 0,
        area_ids: provinceObj
      }]
      _this.$axios({
        method: 'post',
        url: _this.$api.Freight.add_save,
        data: {
          template_id: templateId || 0,
          template_type: templateType,
          base_template: JSON.stringify({
            title: moduleName
          }),
          rule_list: JSON.stringify(ruleList),
          area_type: 2
        }
      }).then(res => {
        const r = res || {}
        if (r.error === 0) {
          _this.$message.success('保存成功')
          _this.$router.push({
            path: '/freight/list'
          })
        } else {
          _this.$message.error(r.msg)
        }
      })
    },
    // 获取地址列表
    getAreaList() {
      this.$axios({
        method: 'post',
        url: this.$api.deliver.getRegion,
        data: { goods_id: 1 }
      }).then((res) => {
        let r = res || {}
        if (r.error === 0) {
          const data = r.data.region_tree || []
          const arr = []
          data.forEach(item => {
            if (item.id === 12596) {
              arr.push(item)
            }
          })
          this.areaOptions = arr
          if (this.templateId) {
            this.getDetail()
          }

          // 收集省市的子集数量
          const obj = {}
          arr.forEach(item => {
            obj[item.id] = item.rows.length
            item.rows.forEach(item2 => {
              obj[item2.id] = item2.rows.length
            })
          })
          this.areaNumObj = obj
        }
      })
    },
    // 详情
    getDetail() {
      const _this = this
      _this.$axios({
        method: 'post',
        url: _this.$api.Freight.git_rule_list,
        data: { template_id: _this.templateId }
      }).then((res) => {
        let r = res || {}
        if (r.error === 0) {
          _this.moduleName = r.data.title || ''
          const arr = []
          const obj = JSON.parse(r.data.rule_list[0].area_ids)['12596']
          if (obj instanceof Array) {
            _this.areaOptions.forEach(item => {
              if (item.id === 12596) {
                item.rows.forEach(t => {
                  t.rows.forEach(r => {
                    arr.push([12596, t.id, r.id])
                  })
                })
              }
            })
          } else {
            Object.keys(obj).forEach(item => {
              if (obj[item] instanceof Array && obj[item].length === 0) {
                _this.areaOptions.forEach(s => {
                  if (s.id === 12596) {
                    s.rows.forEach(t => {
                      if (t.id === parseInt(item)) {
                        t.rows.forEach(r => {
                          arr.push([12596, parseInt(t.id), parseInt(r.id)])
                        })
                      }
                    })
                  }
                })
              } else {
                obj[item].forEach(s => {
                  arr.push([12596, parseInt(item), parseInt(s)])
                })
              }
            })
          }
          _this.freeValue = arr
          _this.tableData = r.data.rule_list || []
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.outer {
  width: 100%;
  min-height: 100%;
}
.cont-item {
  margin-bottom: 40px;
  &>h4 {
    font-size: 18px;
    color: #222;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &>.option {
    display: flex;
    align-items: center;
    padding-left: 16px;
    span {
      white-space: nowrap;
      font-size: 14px;
    }
  }
}
.must-icon {
  color: #E02020;
  margin-right: 10px;
}
.submit-area {
  margin-top: 94px;
}
.remark {
  padding-left: 100px;
  color: #333;
  margin-top: 15px;
  font-size: 14px;
}
.el-cascader {
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
